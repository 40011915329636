.listContainer {
	font-size: 18px;
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;

	.listTitle {
		padding: 20px;
		display: flex;
		justify-content: space-between;

		.link {
			margin: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-decoration: none;
			font-size: 18px;
			font-weight: 600;
		}
	}
	.table {
		width: 100%;
		display: table;

		.header {
			display: table-header-group;
			.headerRow {
				background-color: #2a4e9d;

				.headerCell {
					color: lightcyan;
					font-size: 16px;
					font-weight: 600;
					border-left: 1px lightcyan solid;
					border-radius: 5px;
					position: relative;

					.searchLabel {
						color: white;
					}
				}
			}
		}

		.tableRow {
			border-bottom: 0.2px solid;
			cursor: pointer;

			.cell {
				width: 12%;
				font-size: 14px;
				font-weight: 700;
				color: black;
			}
			.action {
				width: 12%;
				font-size: 14px;
				.delete {
					color: red;
				}
			}

			.pagination {
				border: none;
			}
		}
	}
}
