.vendor-wrapper {
	// font-size: 18px;
	// display: flex;
	// flex-direction: column;
	// -wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	// box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	// border-radius: 10px;
	// padding: 10px;
	// margin: 20px;

	.search-form {
		border-radius: 4px;
		width: 100%;
		padding: 20px;

		.search-title {
			background-color: lightcyan;
			border: 0.5px solid #2a4e9d;
			border-radius: 4px;
			margin-bottom: 3px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 18px;
			font-weight: 800;
			text-transform: uppercase;
			color: #5996cc;
			padding: 20px;

			.vendor-select {
				display: flex;
				// flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 15px;
				margin-top: 10px;
				font-weight: bold;
				color: #2a4e9d;
				margin-right: 5px;

				select {
					background-color: white;
					border: thin solid blue;
					border-radius: 4px;
					display: inline-block;
					font: inherit;
					line-height: 1.5em;
					padding: 0.5em 3.5em 0.5em 1em;
					margin: 0;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					box-sizing: border-box;
					-webkit-appearance: none;
					-moz-appearance: none;
					background-image: linear-gradient(45deg, transparent 50%, blue 50%),
						linear-gradient(135deg, blue 50%, transparent 50%),
						linear-gradient(to right, skyblue, skyblue);
					background-position: calc(100% - 20px) calc(1em + 2px),
						calc(100% - 15px) calc(1em + 2px), 100% 0;
					background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
					background-repeat: no-repeat;
				}
			}
		}
		.date-wrapper {
			background-color: lightcyan;
			border: 0.5px solid #2a4e9d;
			border-radius: 4px;
			margin-bottom: 3px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-size: 18px;
			font-weight: 800;
			text-transform: uppercase;
			color: #5996cc;
			width: 100%;

			.subtitle {
				background-color: lightcyan;
				padding: 20px;
			}
			.srch-dtl {
				background-color: lightcyan;
				border: none;
				padding: 20px;
				margin: 10px;
				width: 90%;
				display: flex;

				.srchItem {
					display: flex;
					flex: 2;
					gap: 10px;
					align-items: center;

					.srch_date_lbl {
						font-size: 18px;
						font-weight: 500;
						color: #2a4e9d;
						font-style: italic;
					}

					.date-in {
						font-size: 18px;
						border: 0.5px solid #2a4e9d;
						border-radius: 4px;
						padding: 2px;
						padding-left: 10px;
					}
				}
				.search-box {
					padding: 10px;
					display: flex;
					justify-content: center;

					.srchBtn {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 10px;
						margin-bottom: 10px;
						width: 120px;
						height: 40px;
						background-color: #2a4e9d;
						color: lightcyan;
						cursor: pointer;
						border-radius: 4px;
						font-weight: 600;
						font-size: 16px;

						&:hover {
							background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
							background-color: #5cbf2a;
						}
					}
				}
			}
		}
	}
	.table-wrapper {
		border-radius: 4px;
		padding: 20px;
		background-color: lightcyan;
		border: 0.5px solid #2a4e9d;
		border-radius: 4px;
		margin-bottom: 3px;
		font-size: 18px;
		font-weight: 800;
		color: #5996cc;
		width: 96.5%;

		.subtitle {
			margin: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.table {
			.headerRow {
				background-color: #2a4e9d;
				color: lightcyan;

				.headerCell {
					color: lightcyan;
					font-size: 16px;
					font-weight: 600;
					border-left: 1px lightcyan solid;
					border-radius: 5px;
					position: relative;

					.searchLabel {
						color: white;
					}
				}
			}
			.tBody {
				.tableRow {
					border-bottom: 0.2px solid;

					.tableCell {
						font-size: 24px;
						color: black;
						font-weight: bold;
					}
				}
			}
		}
	}

	.empty-message {
		display: flex;
		justify-content: center;
		margin-top: 20px;
		text-transform: uppercase;
		font-size: 17px;
		color: #2a4e9d;
	}
}
