.packBatchWrapper {
	font-size: 18px;
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;

	.title {
		background-color: lightcyan;
		border: 0.5px solid #2a4e9d;
		border-radius: 4px;
		margin-bottom: 3px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		font-weight: 600;
		text-transform: uppercase;
		color: #5996cc;
		padding: 20px;
	}

	.buttonSave {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 20px;

		.saveBtn {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			font-size: 16px;
			padding: 10px;
			margin: 20px;
			margin-bottom: 10px;
			height: 50px;
			cursor: pointer;
			border-radius: 4px;
			font-weight: 600;
			background-color: #5cbf2a;
			color: white;

			&:hover {
				background-color: transparent;
				color: #2a4e9d;
				border-color: #fff;
				transition: all 0.1s ease-in-out;
			}
		}
	}
}
