.batchEditCell {
	background-color: #03dac5;
	color: #2a4e9d;
	border-radius: 4px;
	border: lightcyan 1px solid;
	font-size: 14px;
	min-width: 150px;

	.weight {
		background-color: white;
		width: 50px;
		font-size: 14px;
		line-height: 1.5em;
		padding: 0.5em 3.5em 0.5em 1em;
	}

	.selectLot {
		width: 180px;
		background-color: white;
		// border: thin solid #2a4e9d;
		border-radius: 4px;
		display: inline-block;
		font-size: 14px;
		line-height: 1.5em;
		padding: 0.5em 3.5em 0.5em 1em;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-image: linear-gradient(45deg, transparent 50%, #2a4e9d 50%),
			linear-gradient(135deg, #2a4e9d 50%, transparent 50%),
			linear-gradient(to right, skyblue, skyblue);
		background-position: calc(100% - 20px) calc(1em + 2px),
			calc(100% - 15px) calc(1em + 2px), 100% 0;
		background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
		background-repeat: no-repeat;
	}
}
