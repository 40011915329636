.batchMethod-wrapper {
	margin: 10px;
	display: flex;
	align-items: center;
	background-color: lightcyan;
	border: 0.5px solid #2a4e9d;
	flex-direction: column;
	justify-content: center;

	.subtitle {
		color: #5996cc;
		font-size: 18px;
		font-weight: 500;
		display: flex;
		justify-content: center;
		margin: 20px;
		text-decoration: underline;
	}

	.methodTbl {
		font-size: 18px;

		.methodHdr {
			background-color: #2a4e9d;

			.methodHdrCell {
				color: lightcyan;
				border: lightcyan 1px solid;
				font-size: 18px;
			}
		}

		.methodCell {
			background-color: #03dac5;
			color: #2a4e9d;
			border-radius: 4px;
			border: lightcyan 1px solid;
			font-size: 16px;
		}
	}
}
