.navbar {
	height: 50px;
	border-bottom: 0.5px solid rgb(230, 227, 227);
	display: flex;
	align-items: center;
	font-size: 14px;
	background-color: #5996cc;
	color: lightcyan;

	.wrapper {
		display: flex;
		width: 100%;
		align-items: center;
		padding: 20px;
		justify-content: space-between;

		.logoContainer {
			height: 100%;
			width: 7vw;
			padding: 5px;
			display: flex;
			align-items: center;

			.logo {
				width: 42px;
				height: 40px;
				padding: 10px;
				display: flex;
				align-items: center;
			}
		}

		.titleContainet {
			padding: 5px;
			display: flex;
			align-items: center;

			.title {
				display: flex;
				align-items: center;
				color: white;
				font-weight: 800;
				font-size: 20px;
			}
		}

		.items {
			display: flex;
			align-items: center;
			.item {
				display: flex;
				align-items: center;
				margin-right: 20px;
				text-transform: capitalize;
				gap: 5px;

				.tooltip {
					position: relative;
					display: inline-block;
					border-bottom: 1px dotted #5996cc;
				}

				.tooltip .tooltiptext {
					visibility: hidden;
					width: 120px;
					background-color: #5996cc;
					color: #fff;
					text-align: center;
					font-size: 18px;
					border-radius: 6px;
					padding: 5px 0;
					position: absolute;
					z-index: 1;
					top: 150%;
					left: 50%;
					margin-left: -60px;
				}

				.tooltip .tooltiptext::after {
					content: "";
					position: absolute;
					bottom: 100%;
					left: 50%;
					margin-left: -5px;
					border-width: 5px;
					border-style: solid;
					border-color: transparent transparent #5996cc transparent;
				}

				.tooltip:hover .tooltiptext {
					visibility: visible;
				}
				.icon {
					font-size: 20px;
					color: lightcyan;
				}
			}
		}
	}
}
