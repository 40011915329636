.rec-ivnentory-wrapper {
	font-size: 17px;
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	margin: 20px;

	.table {
		background-color: lightcyan;
		border: 0.5px solid #2a4e9d;
		border-radius: 4px;
		margin-bottom: 3px;
		font-size: 16px;
		font-weight: 800;
		color: #5996cc;
		padding: 2px;

		.table-row {
			.ndc {
				width: 18ch;
				background-color: orange;
			}
			.amnt {
				width: 10px;
				background-color: lightgreen;
				color: white;
			}
			.input {
				background-color: lightgreen;
				color: white;
			}
		}

		.pagination {
			width: 100%;
			border: none;
		}
	}
}
