.container {
	display: flex;
	.title {
		margin: 20px;
		color: #5996cc;
		font-size: 18px;
		font-weight: 700;
	}

	.sysresource {
		padding: 10px;
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;

		.formInput {
			display: flex;
			flex-direction: row;
			gap: 5px;
			margin-top: 10px;
			font-weight: normal;
			color: #2a4e9d;
			margin-right: 5px;
			gap: 20px;
			align-items: center;
			justify-content: space-between;
		}

		.input {
			background-color: white;
			border: thin solid #2a4e9d;
			border-radius: 4px;
			display: inline-block;
			font-size: 14px;
			line-height: 1.5em;
			padding: 0.5em 3.5em 0.5em 1em;
			margin: 0;
			// width: 60%;
		}

		.select {
			background-color: white;
			border: thin solid #2a4e9d;
			border-radius: 4px;
			display: inline-block;
			font-size: 14px;
			line-height: 1.5em;
			padding: 0.5em 3.5em 0.5em 1em;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			-webkit-appearance: none;
			-moz-appearance: none;
			background-image: linear-gradient(45deg, transparent 50%, blue 50%),
				linear-gradient(135deg, blue 50%, transparent 50%),
				linear-gradient(to right, skyblue, skyblue);
			background-position: calc(100% - 20px) calc(1em + 2px),
				calc(100% - 15px) calc(1em + 2px), 100% 0;
			background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
			background-repeat: no-repeat;
			width: 60%;
		}

		button {
			margin: 40px;
			width: 45%;
			height: 50%;
			cursor: pointer;
			padding: 10px;
			border: none;
			background-color: #2a4e9d;
			color: white;
			font-weight: bold;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;
		}
	}
}
