.method-list-wrapper {
	margin: 10px;
	.method-list {
		display: table;

		.method-hdr {
			.method-hdr-row {
				background-color: lightcoral;
				.hdrCell {
					color: white;
					font-weight: 600;
					font-size: 17px;
				}
				.action {
					background-color: red;
					color: white;
				}
				.action-hidden {
					display: none;
				}
			}
		}
		.method-row {
			.method-column {
				color: #2a4e9d;
				font-size: 16px;
			}
			.action {
				width: 20px;
			}
			.action-hidden {
				display: none;
			}
		}
	}
}
