.userForm {
	display: flex;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;
	.left {
		flex: 1;
		text-align: center;
		img {
			width: 200px;
			height: 200px;
			border-radius: 40%;
			object-fit: scale-down;
		}
	}

	.right {
		flex: 2;

		.form {
			display: flex;
			flex-wrap: wrap;
			gap: 30px;
			justify-content: space-around;

			.save {
				width: 100%;
				height: 100px;
				align-items: center;
				text-align: center;
				margin: 20px;

				button {
					width: 30%;
					height: 50%;
					cursor: pointer;
					padding: 10px;
					border: none;
					background-color: #2a4e9d;
					color: white;
					font-weight: bold;
				}
			}

			.formInput {
				width: 40%;
				.icon {
					cursor: pointer;
				}

				label {
					display: flex;
					align-items: center;
					gap: 10px;
				}

				input {
					width: 100%;
					padding: 5px;
					border: none;
					border-bottom: 1px solid gray;
				}
			}
		}
	}
}
