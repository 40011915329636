.item-wrapper {
	background-color: #2a4e9d;
	padding: 20px;

	.item-details {
		margin-top: 20px;
		display: flex;
		gap: 25px;
		flex-direction: column;
		font-size: 19px;
		font-weight: 500;
		color: lightcyan;

		span {
			text-decoration: underline;
			margin-right: 5px;
		}
	}
	.item-updt {
		display: flex;
		gap: 40px;
		margin-top: 20px;
		margin-bottom: 15px;
		font-size: 17px;
		font-weight: 500;
		color: lightcyan;

		.detail {
			padding: 10px;
			display: flex;
			gap: 15px;
			justify-content: center;
			align-items: center;

			.dtl-label {
				font-size: 17px;
			}

			.rec-dtl {
				margin-right: 10px;
				padding: 5px;
				background-color: lightcyan;
				color: #2a4e9d;
				font-size: 18px;
				border: none;
				outline: none;
				border-radius: 4px;
			}

			.rec-dtl-dt {
				margin-right: 10px;
				padding: 5px;
				background-color: lightcyan;
				color: #2a4e9d;
				font-size: 18px;
				border: none;
				outline: none;
				border-radius: 4px;

				&::-webkit-calendar-picker-indicator {
					background-color: lightcyan;
					color: #2a4e9d;
					border-radius: 3px;
					cursor: pointer;
				}

				&::after {
					padding: 0;
					margin: 0;
					box-sizing: border-box;
				}
			}
		}
	}
	.save-box {
		margin: 20px;
		padding: 10px;

		.saveBtn {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			padding: 10px;
			width: 130px;
			height: 50px;
			background-color: green;
			color: lightcyan;
			cursor: pointer;
			border-radius: 4px;
			font-weight: 600;
			font-size: 16px;

			&:hover {
				background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
				background-color: #5cbf2a;
			}
		}
	}
}
