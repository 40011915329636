.form {
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding: 10px;

	.inputWrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		justify-content: center;
		background-color: #2a4e9d;
		padding: 10px;
		border-radius: 4px;

		.form-line {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
			// margin-top: 20px;
			padding: 10px;

			.formInput {
				display: flex;
				margin-top: 10px;
				font-weight: normal;
				color: lightcyan;
				margin-right: 5px;
				gap: 10px;
				align-items: center;

				label {
					width: 150px;
					font-size: 16px;
					font-weight: bold;
					text-align: left;
				}
				.form-field {
					height: 36px;
					width: 55%;
					padding: 0 16px;
					outline: 0;
					transition: 0.2s;
					font-size: 16px;
					background-color: white;
					border-radius: 4px;
					line-height: 1.5em;
				}

				.form-field-ndc {
					height: 36px;
					width: 22%;
					padding: 0 16px;
					outline: 0;
					transition: 0.2s;
					font-size: 16px;
					background-color: white;
					border-radius: 4px;
					line-height: 1.5em;
				}
			}
			.selectCntrl {
				display: flex;
				// margin-top: 10px;
				// margin-right: 30px;
				font-weight: normal;
				color: #2a4e9d;
				margin-right: 5px;
				gap: 10px;
				align-items: center;
				width: 100%;

				label {
					width: 150px;
					font-size: 16px;
					font-weight: bold;
					color: lightcyan;
				}

				.select {
					background-color: white;
					// border: thin solid #2a4e9d;
					border-radius: 4px;
					display: inline-block;
					font-size: 14px;
					line-height: 1.5em;
					padding: 0.5em 3.5em 0.5em 1em;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					box-sizing: border-box;
					-webkit-appearance: none;
					-moz-appearance: none;
					background-image: linear-gradient(45deg, transparent 50%, #2a4e9d 50%),
						linear-gradient(135deg, #2a4e9d 50%, transparent 50%),
						linear-gradient(to right, skyblue, skyblue);
					background-position: calc(100% - 20px) calc(1em + 2px),
						calc(100% - 15px) calc(1em + 2px), 100% 0;
					background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
					background-repeat: no-repeat;
				}
			}
		}

		.formInput {
			display: flex;
			gap: 5px;
			margin-top: 10px;
			font-weight: normal;
			color: lightcyan;
			margin-right: 5px;
			gap: 10px;
			width: 100%;

			label {
				width: 150px;
				font-size: 16px;
				font-weight: bold;
				text-align: left;
			}
			.form-field {
				height: 36px;
				width: 20%;
				padding: 0 16px;
				outline: 0;
				transition: 0.2s;
				font-size: 16px;
				background-color: white;
				border: thin solid #2a4e9d;
				border-radius: 4px;
				line-height: 1.5em;
			}

			.form-field-descr {
				width: 70%;
				padding: 10px;
				outline: 0;
				transition: 0.2s;
				font-size: 16px;
				background-color: white;
				border: thin solid #2a4e9d;
				border-radius: 4px;
				line-height: 1.5em;
			}

			.form-field:focus {
				border-color: #2a4e9d;
			}

			.form-field-ndc {
				height: 36px;
				width: 10%;
				padding: 0 16px;
				outline: 0;
				transition: 0.2s;
				font-size: 16px;
				background-color: white;
				border: thin solid #2a4e9d;
				border-radius: 4px;
				line-height: 1.5em;
			}
		}
		.selectCntrl {
			display: flex;
			margin-top: 10px;
			font-weight: normal;
			color: #2a4e9d;
			margin-right: 5px;
			gap: 20px;
			padding: 20px;

			label {
				width: 60px;
				font-size: 16px;
				font-weight: bold;
				color: lightcyan;
			}

			.select {
				background-color: white;
				border: thin solid #2a4e9d;
				border-radius: 4px;
				display: inline-block;
				font-size: 14px;
				line-height: 1.5em;
				padding: 0.5em 3.5em 0.5em 1em;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				-webkit-appearance: none;
				-moz-appearance: none;
				background-image: linear-gradient(45deg, transparent 50%, #2a4e9d 50%),
					linear-gradient(135deg, #2a4e9d 50%, transparent 50%),
					linear-gradient(to right, skyblue, skyblue);
				background-position: calc(100% - 20px) calc(1em + 2px),
					calc(100% - 15px) calc(1em + 2px), 100% 0;
				background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
				background-repeat: no-repeat;
			}
		}
	}

	.controlLabel {
		font-size: 18px;
		font-weight: 700;
		color: #5996cc;
		margin-top: 20px;
		margin-bottom: 5px;
	}

	.checkboxes {
		width: 90%;
		padding: 10px;
		margin: 20px;
		border-radius: 5px;
		background-color: #2a4e9d;
		color: lightcyan;
		gap: 15px;
		padding: 15px;
		width: 96%;
		border-radius: 4px;
		display: flex;
		justify-content: space-between;

		.checkLabel {
			width: 170px;
			text-align: right;
		}

		.controlCheck {
			color: lightcyan;

			&:hover {
				background-color: transparent;
			}
		}
	}
	.save {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;

		.save-button {
			box-shadow: 0px 10px 14px -7px #3dc21b;
			background: linear-gradient(to bottom, #44c767 5%, #5cbf2a 100%);
			background-color: #44c767;
			border-radius: 4px;
			border: 1px solid #18ab29;
			display: inline-block;
			cursor: pointer;
			color: #ffffff;
			font-size: 17px;
			font-weight: bold;
			padding: 14px 40px;
			text-decoration: none;
			text-shadow: 0px 1px 0px #2f6627;

			&:hover {
				background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
				background-color: #5cbf2a;
			}
			&:active {
				position: relative;
				top: 1px;
			}
		}
	}
}
