.method-form {
	display: flex;
	flex-direction: row;
	background-color: #2a4e9d;
	color: lightcyan;
	padding-top: 30px;
	padding-bottom: 30px;
	padding: 20px;
	gap: 20px;
	border-radius: 4px;

	.input-descr {
		.descr {
			border: none;
			outline: none;
			padding: 10px;
			border-radius: 4px;
			font-size: 15px;
		}
	}
	.saveBtn {
		margin: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		font-size: 16px;
		padding: 10px;
		cursor: pointer;
		border-radius: 4px;
		background-color: green;
		color: white;

		&:hover {
			background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
			background-color: #5cbf2a;
		}
	}
}
