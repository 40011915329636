.footer {
	margin-top: calc(5% + 60px);
	// margin-top: 50px;
	margin-top: calc(5% + 60px);
	bottom: 0;
	margin-bottom: 20px;
	font-size: 20px;

	display: flex;
	justify-content: center;
	align-items: center;
}
