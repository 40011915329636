.single {
	display: flex;
	width: 100%;
	.singleContainer {
		flex: 6;
		.top {
			display: flex;
			-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
			box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
			border-radius: 10px;
			padding: 10px;
			margin: 20px;

			.title {
				color: #2a4e9d;

				font-size: 20;
			}
		}
	}
}
