.print {
	display: flex;
	flex-direction: column;
	gap: 5px;
	border: 0.5px solid;
	padding: 15px;
	justify-content: center;
	align-items: center;

	.lblName {
		font-size: 17px;
		font-weight: 600;
		text-decoration: underline;
	}
}
