.po-item-wrapper {
	font-size: 18px;
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;

	.title {
		padding: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 19px;
		font-weight: 700;
		color: #5996cc;
	}
	.po-table-wrapper {
		border: none;

		.table {
			display: table;
			background-color: lightcyan;
			border: 0.5px solid #2a4e9d;
			border-radius: 4px;
			margin-bottom: 3px;
			font-size: 16px;
			font-weight: 800;
			color: #5996cc;
			padding: 2px;

			.header {
				display: table-header-group;
				.headerRow {
					background-color: #2a4e9d;

					.headerCell {
						color: lightcyan;
						font-size: 16px;
						font-weight: 600;
						border-left: 1px lightcyan solid;
						border-radius: 5px;
						position: relative;

						.searchLabel {
							color: white;
						}
					}
				}
			}
			.table-row {
				border-bottom: 0.2px solid;
				cursor: pointer;

				.cell {
					width: 12%;
					font-size: 14px;
					font-weight: 700;
					color: #2a4e9d;
				}

				.ndc {
					width: 15%;
					font-size: 14px;
					font-weight: 700;
					color: #2a4e9d;
					background-color: orange;
				}

				.generic {
					width: 25%;
					font-size: 14px;
					font-weight: 700;
					color: #2a4e9d;
					text-transform: uppercase;
				}
			}
		}
	}
}
