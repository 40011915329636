.batchPackTbl {
	background-color: lightcyan;
	border: 0.5px solid #2a4e9d;

	.batchPackTblHdr {
		background-color: #2a4e9d;

		.batchPackHdrCell {
			color: lightcyan;
			border: lightcyan 1px solid;
			font-size: 18px;
		}
	}
}
