.history-wrapper {
	font-size: 18px;
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;

	.search-form {
		// background-color: #2a4e9d;
		border-radius: 4px;

		.search-title {
			background-color: lightcyan;
			border: 0.5px solid #2a4e9d;
			border-radius: 4px;
			margin-bottom: 3px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 18px;
			font-weight: 800;
			text-transform: uppercase;
			color: #5996cc;

			.subtitle {
				background-color: lightcyan;
				padding: 20px;
			}
		}
		.srch-dtl {
			background-color: lightcyan;
			border: 0.5px solid #2a4e9d;
			border-radius: 4px;
			padding: 20px;
			.srch-box {
				display: flex;
				gap: 40px;

				.srchItem {
					display: flex;
					flex: 2;
					gap: 10px;
					align-items: center;

					.srch-ndc {
						font-size: 18px;
						border: 0.5px solid #2a4e9d;
						border-radius: 4px;
						padding: 2px;
						padding-left: 10px;
						text-transform: uppercase;
					}

					.srch_date_lbl {
						font-size: 18px;
						font-weight: 500;
						color: #2a4e9d;
						font-style: italic;
					}

					.date-in {
						font-size: 18px;
						border: 0.5px solid #2a4e9d;
						border-radius: 4px;
						padding: 2px;
						padding-left: 10px;

						&::-webkit-calendar-picker-indicator {
							cursor: pointer;
						}
					}
				}
			}
			.search-box {
				margin: 20px;
				padding: 10px;
				display: flex;
				justify-content: center;

				.srchBtn {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 10px;
					margin: 20px;
					margin-bottom: 10px;
					width: 130px;
					height: 50px;
					background-color: #2a4e9d;
					color: lightcyan;
					cursor: pointer;
					border-radius: 4px;
					font-weight: 600;
					font-size: 16px;

					&:hover {
						background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
						background-color: #5cbf2a;
					}
				}
			}
		}
	}
	.table-wrapper {
		background-color: lightcyan;
		border: 0.5px solid #2a4e9d;
		border-radius: 4px;
		margin-bottom: 3px;

		font-size: 18px;
		font-weight: 800;
		// text-transform: uppercase;
		color: #5996cc;

		.subtitle {
			margin: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.table {
			.headerRow {
				background-color: #2a4e9d;
				color: lightcyan;

				.headerCell {
					color: lightcyan;
					font-size: 16px;
					font-weight: 600;
					border-left: 1px lightcyan solid;
					border-radius: 5px;
					position: relative;

					.searchLabel {
						color: white;
					}
				}
			}
		}
		.charts {
			display: flex;
			padding: 20px;
			gap: 20px;
		}

		.charts {
			padding: 5px, 20;
		}
	}
}
