.millWrapper {
	margin: 10px;
	display: flex;
	align-items: center;
	background-color: lightcyan;
	border: 0.5px solid #2a4e9d;
	flex-direction: column;
	justify-content: center;
	padding: 10px;

	.title {
		color: #5996cc;
		display: flex;
		justify-content: center;
		margin: 20px;

		.subtitle {
			font-size: 18px;
			font-weight: 500;
			font-style: oblique;
		}
	}

	.millTable {
		margin: 10px;
		border-radius: 4px;

		.millHdr {
			background-color: #2a4e9d;
			border-radius: 4px;

			.millHdrCell {
				color: lightcyan;
				border: lightcyan 1px solid;
				font-size: 18px;
				border-radius: 4px;
			}
		}

		.millingRow {
			background-color: #03dac5;
			color: #2a4e9d;
			border-radius: 4px;
			border: lightcyan 2px solid;
			font-size: 16px;
			cursor: pointer;
		}
	}
}
