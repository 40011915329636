.main {
	display: flex;
	justify-content: center;
	align-items: center;

	.imageWrapper {
		padding: 20px;
		height: 60%;
		width: 60%;
		display: flex;
		justify-content: center;
		img {
			height: 450px;
			width: 950px;
		}
	}
}
