.container {
	font-size: 18px;
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;

	.datatableTitle {
		padding: 20px;
		display: flex;
		justify-content: end;
		width: 100%;

		.link {
			margin: 5px;
			// padding: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-decoration: none;
			font-size: 18px;
			font-weight: 600;
		}
	}

	.table {
		width: 100%;
		display: table;

		.headerRow {
			background-color: #2a4e9d;

			.headerCell {
				color: lightcyan;
				font-size: 16px;
				font-weight: 600;
				border-left: 1px lightcyan solid;
				border-radius: 5px;
			}
		}

		.tableRow {
			border-bottom: 0.2px solid;

			&:nth-of-type(even) {
				// background-color: rgb(135, 206, 250);
				background-color: lightyellow;
			}

			.cell {
				font-size: 15px;
				font-weight: bold;
				.delete {
					color: red;
				}
				color: black;
			}
		}

		.pagination {
			border: none;
		}
	}
}
