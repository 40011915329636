.table-row {
	border-bottom: 0.2px solid;

	.cell {
		width: 12%;
		font-size: 14px;
		font-weight: 700;
		color: black;
		.delete {
			color: red;
		}
	}
	.ndc {
		width: 15%;
		font-size: 14px;
		font-weight: 700;
		color: black;
	}
	.generic {
		width: 25%;
		font-size: 14px;
		font-weight: 700;
		color: black;
	}
}
