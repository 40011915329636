.searchWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px;
	border: #2a4e9d solid 1px;
	padding: 0;
	border-radius: 5px;

	.searchNdc {
		border: none;
		outline: none;
		width: 100%;
		font-size: 15px;
		padding: 2px;
		text-transform: uppercase;
		color: #2a4e9d;
	}
	.srchIcon {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #2a4e9d;
		color: lightcyan;
		cursor: pointer;
	}
}
