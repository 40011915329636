.home {
	display: flex;
	width: 100%;
	.homeContainer {
		flex: 6;
		.top {
			display: flex;
			-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
			box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
			border-radius: 10px;
			padding: 20px;
			margin: 20px;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			.title {
				color: #2a4e9d;
				font-size: 24px;
				font-weight: 800;
				margin-top: 15px;
				margin-bottom: 5px;
			}
			.subtitle {
				color: green;
				font-size: 18px;
				font-weight: 500;
				font-style: italic;
				text-transform: capitalize;
				margin-bottom: 15px;
			}
		}
		.img-wrapper {
			font-size: 18px;
			display: flex;
			flex-direction: column;
			-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
			box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
			border-radius: 10px;
			padding: 10px;
			margin: 20px;
			margin-bottom: 15px;
			align-items: center;
			justify-content: center;

			.logo-image {
				height: 400px;
				width: 400px;
			}
		}
	}
}
