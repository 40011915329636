.list-formula-wrapper {
	font-size: 18px;
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;

	.search-title {
		background-color: lightcyan;
		border: 0.5px solid #2a4e9d;
		border-radius: 4px;
		margin-bottom: 3px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		font-weight: 800;
		text-transform: uppercase;
		color: #5996cc;

		.subtitle {
			background-color: lightcyan;
			padding: 20px;
		}
	}

	.srch-box {
		background-color: lightcyan;
		border: 0.5px solid #2a4e9d;
		border-radius: 4px;
		padding: 20px;
		margin-bottom: 10px;

		.srch-dtl {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 10px;

			label {
				width: 150px;
				color: #5996cc;
			}

			.srchItem {
				display: flex;
				gap: 10px;
				align-items: center;

				.ndc {
					width: 130px;
					font-size: 16px;
					padding: 5px;
				}

				.percent {
					width: 30px;
					font-size: 16px;
					padding: 5px;
				}

				.name {
					width: 300px;
					font-size: 16px;
					padding: 5px;
				}
			}
		}
		.search-box {
			margin: 10px;
			display: flex;
			justify-content: center;

			.srchBtn {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px;
				margin: 20px;
				margin-bottom: 10px;
				width: 130px;
				height: 50px;
				background-color: #2a4e9d;
				color: lightcyan;
				cursor: pointer;
				border-radius: 4px;
				font-weight: 600;
				font-size: 16px;

				&:hover {
					background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
					background-color: #5cbf2a;
				}
			}
		}
	}

	.list-fomrula-container {
		background-color: lightcyan;
		border: 0.5px solid #2a4e9d;
		border-radius: 4px;
		margin-bottom: 3px;
		font-size: 18px;
		font-weight: 800;

		color: #5996cc;
		.list-table {
			.tBody {
				.tableRow {
					border-bottom: 0.2px solid;

					.tableCell {
						font-size: 24px;
						color: black;
						font-weight: bold;
					}
				}
			}
		}
	}
}
