.review-wrapper {
	font-size: 18px;
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;

	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 20%;
		padding: 20px;
		color: #2a4e9d;

		.avatar {
			background-color: #2a4e9d;
			color: lightcyan;
		}
	}
	.formula-info {
		display: flex;
		flex-direction: column;

		.table-title {
			color: #2a4e9d;
			font-size: 19px;
			font-weight: 600;
			text-decoration: none;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 20px;
		}

		.formula-table {
			display: table;
			margin: 20px;
			border: #2a4e9d 0.5px solid;
			border-radius: 4px;
			padding: 10px;

			.info-column {
				background-color: #2a4e9d;
				color: lightcyan;
				border: white 0.5px solid;
				border-radius: 4px;
				font-weight: 500;
				font-size: 17px;
				width: 35%;
			}

			.data-column {
				font-style: oblique;
				color: #2a4e9d;
				font-size: 16px;
				background-color: lightcyan;
				border-radius: 4px;
			}
		}
	}
	.stepper-buttons {
		display: flex;
		justify-content: center;
		gap: 20px;
		margin: 20px;

		.submit {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px;
			margin: 20px;
			margin-bottom: 10px;
			width: 130px;
			height: 50px;
			background-color: #2a4e9d;
			color: lightcyan;
			gap: 10px;
			cursor: pointer;
			border-radius: 4px;
			font-weight: 600;
			font-size: 16px;

			&:hover {
				background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
				background-color: #5cbf2a;
			}
		}
	}

	.center {
		display: flex;
		align-items: center;
		justify-content: center;

		.save {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			font-size: 16px;
			padding: 10px;
			margin: 20px;
			margin-bottom: 10px;
			height: 50px;
			cursor: pointer;
			border-radius: 4px;
			font-weight: 600;
			background-color: #5cbf2a;
			color: white;

			&:hover {
				background-color: transparent;
				color: #2a4e9d;
				border-color: #fff;
				transition: all 0.1s ease-in-out;
			}
		}
	}
}
