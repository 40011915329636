.step-wrapper {
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding: 10px;
}
.instructions {
	margin-top: 10px;
	margin-bottom: 10px;
}
