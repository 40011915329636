.lblBatchTbl {
	font-size: 18px;

	.lblHdr {
		background-color: #2a4e9d;

		.lblHdrCell {
			color: lightcyan;
			border: lightcyan 1px solid;
			font-size: 18px;
		}
	}
}
.saveBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px;

	.saveButton {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
		margin: 20px;
		margin-bottom: 10px;
		width: 130px;
		height: 50px;
		background-color: #2a4e9d;
		color: lightcyan;
		cursor: pointer;
		border-radius: 4px;
		font-weight: 600;
		font-size: 16px;

		&:hover {
			background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
			background-color: #5cbf2a;
		}
	}
}
