$left-color: #242e4d;
$right-color: #897e79;
$green-dark: #35c3c1;
$green-light: #00d6b7;
$gray: #8f8f8f;
$gray-light: #f5f6f8;

.login {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	* {
		-webkit-font-smoothing: antialiased;
		text-rendering: optimizeLegibility;

		.nav {
			display: flex;
			flex-direction: row;
			gap: 40px;
			margin-top: 20px;
			font-size: 20px;
			font-weight: 500;
			color: white;
		}
	}

	.container {
		margin-top: 10%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		border: 1px solid #555;
		border-radius: 10px;
		padding: 10px;
		background-color: #2a4e9d;
		box-shadow: 2.8px 2.9px 2.2px rgba(0, 0, 0, 0.031),
			6.7px 7px 5.3px rgba(0, 0, 0, 0.044),
			12.5px 13.1px 10px rgba(0, 0, 0, 0.055),
			22.3px 23.5px 17.9px rgba(0, 0, 0, 0.066),
			41.8px 43.9px 33.4px rgba(0, 0, 0, 0.079),
			100px 105px 80px rgba(0, 0, 0, 0.11);

		.title {
			margin: 40px;
			color: white;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.logo {
				height: 100px;
				width: 100px;
			}

			.titleText {
				font-size: 18px;
				margin-top: 20px;
				font-weight: 600;
			}
		}

		.row {
			display: flex;
			margin: 10px;
			margin-top: 30px;

			.label {
				width: 2em;
				display: flex;
				align-items: center;
				justify-content: center;
				background: $gray-light;
				border: 0.5px solid;
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
			}
			.input {
				flex: 1;
				padding: 1em;
				border: 0.5px solid;
				color: $gray;
				font-size: 18px;
				width: 450px;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
			}

			button {
				position: relative;
				border: none;
				background: transparent;
				padding: 10;
				cursor: pointer;
				outline-offset: 4px;
				transition: filter 250ms;
				user-select: none;
				touch-action: manipulation;
				margin-top: 20px;
				font-weight: bold;
			}

			.shadow {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 12px;
				background: hsl(0deg 0% 0% / 0.25);
				will-change: transform;
				transform: translateY(2px);
				transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
			}

			.edge {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 12px;
				background: linear-gradient(
					to left,
					hsl(340deg 100% 16%) 0%,
					hsl(340deg 100% 32%) 8%,
					hsl(340deg 100% 32%) 92%,
					hsl(340deg 100% 16%) 100%
				);
			}

			.front {
				display: block;
				position: relative;
				padding: 12px 27px;
				border-radius: 12px;
				font-size: 1.1rem;
				color: white;
				background: hsl(345deg 100% 47%);
				will-change: transform;
				transform: translateY(-4px);
				transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
			}

			button:hover {
				filter: brightness(110%);
			}

			button:hover .front {
				transform: translateY(-6px);
				transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
			}

			button:active .front {
				transform: translateY(-2px);
				transition: transform 34ms;
			}

			button:hover .shadow {
				transform: translateY(4px);
				transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
			}

			button:active .shadow {
				transform: translateY(1px);
				transition: transform 34ms;
			}

			button:focus:not(:focus-visible) {
				outline: none;
			}
		}
		.passLink {
			width: 100%;
			cursor: pointer;
			padding: 5px;
			margin-top: 15px;
			color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10%;

			.link {
				color: white;
				font-weight: bold;
				text-decoration: none;
				font-weight: 600;
			}
		}
	}
}
