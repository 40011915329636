.userContainer {
	padding: 20px;
	display: flex;
	gap: 20px;
	height: 60vh;

	.left {
		flex: 1;
		padding: 20px;
		position: relative;
		-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
		box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
		border-radius: 10px;

		.item {
			display: flex;
			gap: 20px;

			.itemImg {
				height: 100px;
				width: 100px;
				border-radius: 10px;
				object-fit: cover;
			}
			.details {
				position: relative;
				.itemTitle {
					margin-bottom: 10px;
					color: #2a4e9d;
					text-transform: capitalize;
				}

				.itemDetail {
					margin-bottom: 10px;
					font-size: 16px;

					.itemKey {
						font-weight: bold;
						color: #2a4e9d;
						margin-right: 5px;
					}

					.itemValue {
						font-weight: 300;
					}
				}
				.roleSelect {
					display: flex;
					flex-direction: column;
					gap: 5px;
					margin-top: 10px;
					font-weight: bold;
					color: #2a4e9d;
					margin-right: 5px;

					select {
						/* styling */
						background-color: white;
						border: thin solid blue;
						border-radius: 4px;
						display: inline-block;
						font: inherit;
						line-height: 1.5em;
						padding: 0.5em 3.5em 0.5em 1em;
						margin: 0;
						-webkit-box-sizing: border-box;
						-moz-box-sizing: border-box;
						box-sizing: border-box;
						-webkit-appearance: none;
						-moz-appearance: none;
						background-image: linear-gradient(45deg, transparent 50%, blue 50%),
							linear-gradient(135deg, blue 50%, transparent 50%),
							linear-gradient(to right, skyblue, skyblue);
						background-position: calc(100% - 20px) calc(1em + 2px),
							calc(100% - 15px) calc(1em + 2px), 100% 0;
						background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
						background-repeat: no-repeat;
					}
				}
			}
		}
	}
	.right {
		flex: 2;
	}
	button {
		position: relative;
		border: none;
		background: transparent;
		padding: 10;
		cursor: pointer;
		outline-offset: 4px;
		transition: filter 250ms;
		user-select: none;
		touch-action: manipulation;
		margin-top: 20px;
		font-weight: bold;

		.shadow {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 12px;
			background: hsl(0deg 0% 0% / 0.25);
			will-change: transform;
			transform: translateY(2px);
			transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
		}
		.edge {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 12px;
			background: #2a4e9d;
		}

		.front {
			display: flex;
			position: relative;
			padding: 12px 27px;
			border-radius: 10px;
			font-size: 1.1rem;
			color: lightcyan;
			background: #5996cc;
			will-change: transform;
			transform: translateY(-4px);
			transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
		}
		&:hover {
			filter: brightness(110%);
		}

		&:hover .front {
			transform: translateY(-6px);
			transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
		}

		&:active .front {
			transform: translateY(-2px);
			transition: transform 34ms;
		}

		&:hover .shadow {
			transform: translateY(4px);
			transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
		}

		&:active .shadow {
			transform: translateY(1px);
			transition: transform 34ms;
		}

		&:focus:not(:focus-visible) {
			outline: none;
		}
	}
}
