.vendor-wrapper {
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 5px;
	margin: 15px;
	justify-content: center;
	align-items: center;
}
