.table-wrapper {
	font-size: 18px;
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;

	.table-title {
		padding: 20px;
		display: flex;
		justify-content: end;

		.link {
			margin: 5px;
			// padding: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-decoration: none;
			font-size: 18px;
			font-weight: 600;
		}
	}

	.req-table {
		background-color: lightcyan;
		border: 0.5px solid #2a4e9d;
		border-radius: 4px;
		margin-bottom: 3px;
		font-size: 16px;
		font-weight: 800;
		color: #5996cc;
		padding: 2px;
		.header {
			.headerRow {
				background-color: #2a4e9d;

				.headerCell {
					color: lightcyan;
					font-size: 16px;
					font-weight: 600;
					border-left: 1px lightcyan solid;
					border-radius: 5px;
				}
			}
		}
		.tableBody {
			.tableRow {
				.ndc {
					background-color: orange;
				}
			}
		}
	}
}
