.order-item-wrapper {
	font-size: 18px;
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;

	.search-form {
		// background-color: #2a4e9d;
		border-radius: 4px;
		width: 100%;

		.search-title {
			background-color: lightcyan;
			border: 0.5px solid #2a4e9d;
			border-radius: 4px;
			margin-bottom: 3px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 18px;
			font-weight: 800;
			text-transform: uppercase;
			color: #5996cc;

			.subtitle {
				background-color: lightcyan;
				padding: 20px;
			}
		}
		.srch-dtl {
			background-color: lightcyan;
			border: 0.5px solid #2a4e9d;
			border-radius: 4px;
			padding: 20px;
			.search-box {
				display: flex;
				gap: 40px;

				.search-item {
					display: flex;
					flex: 2;
					gap: 10px;
					align-items: center;

					.srch-ndc {
						font-size: 18px;
						border: 0.5px solid #2a4e9d;
						border-radius: 4px;
						padding: 2px;
						padding-left: 10px;
						text-transform: uppercase;
					}
				}
				.search-btn {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 10px;
					margin: 20px;
					margin-bottom: 10px;
					width: 130px;
					height: 50px;
					background-color: #2a4e9d;
					color: lightcyan;
					cursor: pointer;
					border-radius: 4px;
					font-weight: 600;
					font-size: 16px;

					&:hover {
						background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
						background-color: #5cbf2a;
					}
				}
			}
		}
	}
}
.item-order-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5px;
	margin-top: 2px;

	.form {
		display: flex;
		flex-direction: column;
		gap: 15px;
		padding: 20px;
		background-color: #2a4e9d;
		padding: 10px;
		border-radius: 4px;
		color: lightcyan;
		border-radius: 4px;
		border: 0.5px solid #2a4e9d;

		.line {
			border: 0.5px solid lightcyan;
			border-radius: 4px;
			margin: 5px;
			padding: 20px;

			.lineElement {
				display: flex;
				align-items: center;
				justify-content: start;

				.label {
					width: 200px;
					display: flex;
					align-items: center;
					// justify-content: flex-start;
					color: yellow;
					font-weight: italic;
					font-size: 18px;
					text-decoration: underline;
					text-transform: uppercase;
					float: right;
				}

				.formInput {
					display: flex;

					.dataField {
						font-size: 18px;
						border: 0.5px solid #2a4e9d;
						border-radius: 4px;
						padding: 2px;
						padding-left: 10px;

						&::-webkit-calendar-picker-indicator {
							cursor: pointer;
						}
					}
				}
			}
		}
		.save-order {
			display: flex;
			width: 50%;

			.save-button {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px;
				margin: 20px;
				margin-bottom: 10px;
				width: 130px;
				height: 50px;
				background-color: #2a4e9d;
				color: lightcyan;
				cursor: pointer;
				border-radius: 4px;
				font-weight: 600;
				font-size: 16px;

				&:hover {
					background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
					background-color: #5cbf2a;
				}
			}
		}
	}
}
