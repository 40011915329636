.batchHdrWrapper {
	display: flex;
	justify-content: space-between;

	.hdrTitle {
		display: flex;
		flex-direction: column;
		padding: 20px;
		gap: 5px;
		color: #2a4e9d;
	}

	.printWrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}
}
