.ingridient-wrapper {
	background-color: #2a4e9d;
	padding: 20px;
	border-radius: 4px;

	.srch-box {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 10px;
		border: lightcyan solid 1px;
		padding: 10px;
		border-radius: 5px;
		color: lightcyan;
		gap: 20px;

		.srchLbl {
			color: lightcyan;
		}
		.ndc-wrapper {
			display: flex;
			flex-direction: row;

			.searchNdc {
				border: none;
				outline: none;
				font-size: 18px;
				text-transform: uppercase;
				color: #2a4e9d;
				padding: 5px;
			}
			.srchIcon {
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #2a4e9d;
				color: lightcyan;
				cursor: pointer;
			}
		}
	}

	.ingrid-info {
		display: flex;
		flex-direction: column;
		margin-top: 10px;
		font-weight: normal;
		color: #e0ffff;
		margin-right: 5px;
		gap: 10px;
		justify-content: center;
		align-items: center;
		font-size: 17px;

		label {
			width: 200px;
			font-size: 16px;
			font-weight: bold;
			text-align: left;
		}

		.info-field {
			display: flex;
			align-items: center;
			// justify-content: center;

			label {
				width: 80px;
				font-size: 16px;
				font-weight: bold;
				text-align: left;
			}

			.amount {
				// background-color: #008000;
				width: 5ch;
				font-size: 18px;
				color: #2a4e9d;
				padding: 5px;
			}
		}
		.check-boxes {
			color: lightcyan;
			font-size: 20px;
		}

		.consideration {
			color: yellow;
			font-size: 18px;
			margin-left: 20px;
		}
	}
	.save-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;

		.saveBtn {
			margin: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			font-size: 16px;
			padding: 10px;
			cursor: pointer;
			border-radius: 4px;
			background-color: green;
			color: white;

			&:hover {
				background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
				background-color: #5cbf2a;
			}
		}
	}
}
