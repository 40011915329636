.batch-rx-wrapper {
	.title {
		display: flex;
		align-items: center;
		background-color: lightcyan;
		border: 0.5px solid #2a4e9d;
		margin: 10px;
		flex-direction: column;
		justify-content: center;

		.header {
			font-size: 18px;
			color: #5996cc;
			padding: 20px;
			font-weight: 600;
			text-decoration: underline;
		}
		.subheader {
			font-size: 16px;
			font-style: oblique;
			color: #5996cc;
			font-weight: 500;
			margin-bottom: 20px;
		}

		form {
			display: flex;
			gap: 20px;
			font-size: 18px;
			color: #5996cc;
			font-weight: 500;
			align-items: center;

			.rx {
				font-size: 17px;
				padding: 5px;
				margin: 10px;
				margin-bottom: 20px;
			}

			.addRx {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;
				font-size: 17px;
				padding: 10px;
				height: 40px;
				cursor: pointer;
				color: lightcyan;
				background-color: #2a4e9d;
				border-radius: 4px;
				margin-bottom: 15px;
				&:hover {
					background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
					background-color: #5cbf2a;
				}
			}
		}
	}
	.rxTable {
		font: 18px;
		border: #2a4e9d 0.5px solid;
		margin-top: 10px;

		.rxHdr {
			background-color: #2a4e9d;
			color: lightcyan;
			border-radius: 4px;
			border: lightcyan 1px solid;
			font-size: 18px;
		}

		.rxNo {
			background-color: #03dac5;
			color: #2a4e9d;
			border-radius: 4px;
			border: lightcyan 1px solid;
			font-size: 16px;
		}

		.rxWght {
			background-color: #03dac5;
			color: #2a4e9d;
			border-radius: 4px;
			border: lightcyan 1px solid;
			font-size: 16px;
		}
	}
}
