.sidebar {
	flex: 1.2;
	border-right: 0.5px solid rgb(230, 227, 227);
	min-height: 50px;
	background-color: white;

	.link {
		text-decoration: none;
	}

	.top {
		margin-top: 0;
		margin-bottom: 5px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 0.5px solid #404854;
		border-bottom: 0.5px solid #404854;
		background-color: #5996cc;
		color: lightcyan;

		.smallIcon {
			color: #5996cc;
			padding-top: 10px;
			padding-bottom: 10px;
			// border-right: 1px solid lightcyan;
			border: none;
			border-radius: 50%;
			&:hover {
				background: "none";
			}
			.logo {
				width: 42px;
				height: 40px;
				padding: 10px;
				display: flex;
				align-items: center;
			}
		}

		.title {
			font-size: 20px;
			font-weight: bold;
			margin: 10px;
			text-decoration: none;
			color: lightcyan;
			font-weight: 600;
		}
	}

	.item {
		&:hover {
			background: rgba(140, 114, 114, 0.1);
		}

		padding-bottom: 5px;

		.activeItem {
			& * {
				color: #4fc3f7;
			}
		}
		.itemText {
			font-size: 14px;
			font-weight: 400;
			color: #5996cc;
		}

		.itemIcon {
			margin: 0;
			color: #2a4e9d;
		}
	}

	.categoryHeader {
		padding-top: 20px;
		padding-bottom: 10px;
		text-decoration: underline;
		color: #5996cc;

		.categoryHeaderText {
			font-size: 15px;
			font-weight: bold;
		}
	}

	.divider {
		margin-top: 5px;
		background: #3484b3;
	}
}
