.batchRxLbl-wrapper {
	font-size: 18px;
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;

	.title {
		color: #5996cc;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 20px;
		flex-direction: column;
		gap: 10px;

		.subtitle {
			font-size: 18px;
			font-weight: 500;
			font-style: oblique;
		}
	}
}
