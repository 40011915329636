.item-wrapper {
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 5px;
	margin: 15px;
	justify-content: center;
	align-items: center;

	.form {
		display: flex;
		flex-direction: column;
		gap: 15px;
		padding: 20px;
		background-color: #2a4e9d;
		padding: 10px;
		border-radius: 4px;
		width: 100%;
		color: lightcyan;

		.line {
			display: flex;
			justify-content: space-between;
			flex-direction: row;
			// gap: 20px;
			border: 0.5px solid lightcyan;
			border-radius: 4px;
			margin: 5px;
			padding: 20px;

			.lineElement {
				display: flex;
				align-items: center;

				.label {
					width: 200px;
					display: flex;
					align-items: center;
					// justify-content: flex-start;
					color: yellow;
					font-weight: italic;
					font-size: 18px;
					text-decoration: underline;
					text-transform: uppercase;
					float: right;
				}
				.dataField {
					font-weight: normal;
					font-size: 16px;
					width: 260px;
					float: right;
				}
				.formInput {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 260px;
				}
			}
		}
	}
}
