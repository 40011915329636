.method-wrapper {
	font-size: 18px;
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;

	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 20%;
		padding: 20px;
		color: #2a4e9d;

		.avatar {
			background-color: #2a4e9d;
			color: lightcyan;
		}
	}

	.stepper-buttons {
		display: flex;
		justify-content: center;
		gap: 20px;
		margin: 20px;

		.submit {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px;
			margin: 20px;
			margin-bottom: 10px;
			width: 130px;
			height: 50px;
			background-color: #2a4e9d;
			color: lightcyan;
			gap: 10px;
			cursor: pointer;
			border-radius: 4px;
			font-weight: 600;
			font-size: 16px;

			&:hover {
				background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
				background-color: #5cbf2a;
			}
		}
	}
}
