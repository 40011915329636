.container {
	display: flex;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;

	.header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 20px;
		padding: 10px;

		.title {
			font-size: 20px;
			font-weight: 700;
		}

		.subtitle {
			font-size: 16px;
			font-weight: 500;
			color: #5996cc;
			font-style: italic;
		}
	}

	.list {
		-moz-column-count: 4;
		-moz-column-gap: 20px;
		-webkit-column-count: 4;
		-webkit-column-gap: 20px;
		column-count: 4;
		column-gap: 20px;
		border-top: 0.5px #2a4e9d solid;
		border-bottom: 0.5px #2a4e9d solid;
		padding: 20px;
	}

	.save {
		display: flex;
		margin: 25px;
		padding: 10px;

		.saveBtn {
			display: flex;
			align-items: center;
			justify-content: center;
			color: white;
			background-color: #2a4e9d;
			border-radius: 10px;
			height: 60px;
			width: 200px;
			font-size: 16px;
			gap: 10px;
			cursor: pointer;
		}
	}
}
