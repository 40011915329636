.container {
	display: flex;
	justify-content: center;
	align-items: center;

	.imageWrapper {
		padding: 20px;
		display: flex;
		justify-content: center;

		img {
			height: 450px;
			width: 950px;
		}
	}
}
