.completeBatch {
	font-size: 18px;
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;

	.title {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px;

		.ndc {
			font-size: 18px;
			color: #2a4e9d;
			font-weight: 600;
			text-decoration: underline;
		}

		.hdrField {
			font-size: 17px;
			color: #2a4e9d;
			font-weight: 500;
		}
	}

	.usersWrapper {
		display: flex;
		flex-direction: column;
		margin: 20px;
		gap: 5px;
		justify-content: center;
		align-items: center;

		.userTitle {
			font-size: 17px;
			font-weight: 600;
			text-decoration: underline;
			color: #2a4e9d;
		}

		.user {
			font-size: 16px;
			font-weight: 500;
			font-style: oblique;
			color: #2a4e9d;
		}
	}
}
