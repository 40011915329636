.header {
	display: table-header-group;
	.headerRow {
		background-color: #2a4e9d;

		.headerCell {
			color: lightcyan;
			font-size: 16px;
			font-weight: 600;
			border-left: 1px lightcyan solid;
			border-radius: 5px;
			position: relative;

			.searchLabel {
				color: white;
			}
		}
	}
}

.toolbar-wrapper {
	.title {
		flex: 1 1 100%;
		color: #5996cc;
	}
	.delete {
		color: red;
	}

	.add {
		color: green;
	}
}
