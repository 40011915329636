.compoundingWrapper {
	font-size: 18px;
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;

	.header {
		.subtitle {
			background-color: lightcyan;
			margin-bottom: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 17px;
			font-weight: 600;
			color: #5996cc;
			padding: 20px;
		}
	}

	.queueContainer {
		background-color: lightcyan;
		border: 0.5px solid #2a4e9d;
		border-radius: 4px;
		margin-bottom: 3px;
		color: #5996cc;

		.compound-tbl {
			.queueHdr {
				background-color: #2a4e9d;

				.hdrCell {
					color: lightcyan;
					font-size: 18px;
					font-weight: 600;
				}
			}

			.row {
				cursor: pointer;
				background-color: #03dac5;
				color: #2a4e9d;

				th {
					font-size: 16px;
					font-weight: 500;
				}
			}
		}
	}
}
