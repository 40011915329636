.po-wrapper {
	font-size: 18px;
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;

	.title {
		background-color: lightcyan;
		border: 0.5px solid #2a4e9d;
		border-radius: 4px;
		margin-bottom: 3px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		font-weight: 800;
		text-transform: uppercase;
		color: #5996cc;
		padding: 20px;

		.vendor-select {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 15px;
			margin-top: 10px;
			font-weight: bold;
			color: #2a4e9d;
			margin-right: 5px;

			select {
				background-color: white;
				border: thin solid blue;
				border-radius: 4px;
				display: inline-block;
				font: inherit;
				line-height: 1.5em;
				padding: 0.5em 3.5em 0.5em 1em;
				margin: 0;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				-webkit-appearance: none;
				-moz-appearance: none;
				background-image: linear-gradient(45deg, transparent 50%, blue 50%),
					linear-gradient(135deg, blue 50%, transparent 50%),
					linear-gradient(to right, skyblue, skyblue);
				background-position: calc(100% - 20px) calc(1em + 2px),
					calc(100% - 15px) calc(1em + 2px), 100% 0;
				background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
				background-repeat: no-repeat;
			}
		}
	}
	.vendor-po-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.5px;
		margin-top: 2px;
		flex-direction: column;
		background-color: lightcyan;
		border: 0.5px solid #2a4e9d;
		border-radius: 4px;
		width: 100%;

		.title {
			margin-bottom: 3px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			gap: 10px;
			font-size: 18px;
			font-weight: 800;
			text-transform: uppercase;
			color: #5996cc;
			padding: 20px;
			border: none;
		}
		.line {
			border: none;
			margin: 5px;
			padding: 20px;
			border: #2a4e9d 0.5px solid;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-evenly;
			gap: 10px;

			.lineElement {
				display: flex;
				align-items: flex-start;
				// gap: 10px;

				.label {
					width: 200px;
					display: flex;
					align-items: center;
					color: #2a4e9d;
					font-weight: 600;
					font-size: 18px;
					text-decoration: underline;
					text-transform: uppercase;
					float: right;
				}

				.dataField {
					font-size: 18px;
					border: none;
					width: 240px;
					color: green;
					font-weight: 500;
				}
			}
		}
	}

	.center {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		.process {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px;
			margin: 20px;
			margin-bottom: 10px;
			width: 130px;
			height: 50px;
			background-color: #2a4e9d;
			color: lightcyan;
			cursor: pointer;
			border-radius: 4px;
			font-weight: 600;
			font-size: 16px;

			&:hover {
				background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
				background-color: #5cbf2a;
			}
		}
	}
}
