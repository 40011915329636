.lblBatchTbl {
	font-size: 18px;
	margin: 10px;
	margin-right: 10px;

	.lblHdr {
		background-color: #2a4e9d;

		.lblHdrCell {
			color: lightcyan;
			border: lightcyan 1px solid;
			font-size: 18px;
		}
	}

	.lblCell {
		background-color: #03dac5;
		color: #2a4e9d;
		border-radius: 4px;
		border: lightcyan 1px solid;
		font-size: 15px;
	}
}
