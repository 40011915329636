.lblQueue {
	font-size: 18px;
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;

	.title {
		color: #5996cc;
		display: flex;
		justify-content: center;
		margin: 20px;

		.subtitle {
			font-size: 18px;
			font-weight: 500;
			font-style: oblique;
		}
	}

	.lblTbl {
		margin: 10px;
		border-radius: 4px;

		.lblHdr {
			background-color: #2a4e9d;
			border-radius: 4px;

			.lblHdrCell {
				color: lightcyan;
				border: lightcyan 1px solid;
				font-size: 18px;
				border-radius: 4px;
			}
		}
		.lblRow {
			background-color: #03dac5;
			color: #2a4e9d;
			border-radius: 4px;
			border: lightcyan 2px solid;
			font-size: 16px;
			cursor: pointer;
		}
	}
}
