.batchIngrid-wrapper {
	margin: 10px;
	display: flex;
	align-items: center;
	background-color: lightcyan;
	border: 0.5px solid #2a4e9d;
	flex-direction: column;
	justify-content: center;

	.ingridHdr {
		display: flex;
		justify-content: center;
		flex-direction: column;

		.title {
			color: #5996cc;
			font-size: 18px;
			font-weight: 500;
			display: flex;
			justify-content: center;
			margin: 20px;
			text-decoration: underline;
		}

		.subtitle {
			font-size: 16px;
			font-style: oblique;
			color: #5996cc;
			font-weight: 500;
			margin-bottom: 20px;
		}
	}
	.ingridTbl {
		font-size: 18px;

		.hdrCell {
			color: lightcyan;
			border: lightcyan 1px solid;
			font-size: 18px;
			background-color: #2a4e9d;
			border: 4px;
		}

		.ingridCell {
			background-color: #03dac5;
			color: #2a4e9d;
			border-radius: 4px;
			border: lightcyan 1px solid;
			font-size: 15px;
		}
	}
}
