.batchInventoryWrapper {
	margin: 10px;
	display: flex;
	align-items: center;
	background-color: lightcyan;
	border: 0.5px solid #2a4e9d;
	flex-direction: column;
	justify-content: center;

	.batchInvnetoryTbl {
		.invHdr {
			background-color: #2a4e9d;

			.invHdrCell {
				color: lightcyan;
				border: lightcyan 1px solid;
				font-size: 18px;
			}
		}
	}
}
