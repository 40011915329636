$left-color: #242e4d;
$right-color: #897e79;
$green-dark: #35c3c1;
$green-light: #00d6b7;
$gray: #8f8f8f;
$gray-light: #f5f6f8;

.password-wrapper {
	margin-top: 10%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border: 1px solid #555;
	border-radius: 10px;
	padding: 10px;
	background-color: #2a4e9d;
	box-shadow: 2.8px 2.9px 2.2px rgba(0, 0, 0, 0.031),
		6.7px 7px 5.3px rgba(0, 0, 0, 0.044),
		12.5px 13.1px 10px rgba(0, 0, 0, 0.055),
		22.3px 23.5px 17.9px rgba(0, 0, 0, 0.066),
		41.8px 43.9px 33.4px rgba(0, 0, 0, 0.079),
		100px 105px 80px rgba(0, 0, 0, 0.11);

	.title {
		margin: 40px;
		color: white;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 20px;

		.logo {
			height: 100px;
			width: 100px;
		}

		.titleText {
			font-size: 22px;
			margin-top: 20px;
			font-weight: 600;
		}
	}
	.form-password {
		.form-input {
			display: flex;
			justify-content: start;
			align-items: center;
			gap: 10px;
			margin: 20px;

			label {
				display: flex;
				color: lightcyan;
				text-transform: uppercase;
				width: 200px;
				align-items: flex-start;
			}
			.email {
				font-size: 18px;
				font-weight: 500;
				border: none;
				outline: none;
				padding: 0.5rem;
				width: 400px;
			}
			.item {
				display: flex;
				align-items: center;

				.pass-input {
					font-size: 18px;
					font-weight: 500;
					border: none;
					outline: none;
				}
				.visIcon {
					border: none;
					outline: none;
					height: 27px;
					cursor: pointer;
				}
			}
		}

		.save {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			margin: 20px;

			.save-button {
				background: linear-gradient(to bottom, #44c767 5%, #5cbf2a 100%);
				background-color: #44c767;
				border-radius: 4px;
				border: 1px solid #18ab29;
				display: flex;
				gap: 10px;
				cursor: pointer;
				color: #ffffff;
				font-size: 17px;
				font-weight: bold;
				padding: 14px 40px;
				text-decoration: none;
				text-shadow: 0px 1px 0px #2f6627;

				&:hover {
					background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
					background-color: #5cbf2a;
				}
			}
		}
	}
}
