.ingrid-list-wrapper {
	margin: 10px;
	padding: 5px;

	.ingrid-table {
		display: table;

		.tblHeader {
			color: #2a4e9d;
			background-color: lightcoral;

			.hdrCell {
				color: white;
				font-weight: 600;
				font-size: 17px;
			}

			.action-ingrid {
				color: white;
				font-size: 14px;
				background-color: red;
			}
			.action-hidden {
				display: none;
			}
		}
		.ingirid-row {
			color: #2a4e9d;

			.ingirid-column {
				color: #2a4e9d;
				font-size: 16px;
			}

			.action-hidden {
				display: none;
			}
		}
	}
}
