.password-wrapper {
	font-size: 18px;
	display: flex;
	flex-direction: column;
	-wbekit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
	box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
	border-radius: 10px;
	padding: 10px;
	margin: 20px;

	.pass-title {
		padding: 20px;
		display: flex;
		justify-content: center;
	}
	.password-form {
		display: flex;
		flex-direction: column;
		gap: 15px;
		background-color: #2a4e9d;
		padding: 10px;
		border-radius: 4px;
		justify-content: center;
		align-items: center;

		.form-input {
			display: flex;
			justify-content: start;
			align-items: center;
			gap: 10px;
			margin: 20px;

			label {
				display: flex;
				color: lightcyan;
				text-transform: uppercase;
				width: 200px;
				align-items: flex-start;
			}
			.item {
				display: flex;
				align-items: center;
				.pass-input {
					font-size: 18px;
					font-weight: 500;
					border: none;
					outline: none;
				}
				.visIcon {
					border: none;
					outline: none;
					height: 27px;
					cursor: pointer;
				}
			}
		}

		.save {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			margin: 20px;

			.save-button {
				background: linear-gradient(to bottom, #44c767 5%, #5cbf2a 100%);
				background-color: #44c767;
				border-radius: 4px;
				border: 1px solid #18ab29;
				display: flex;
				gap: 10px;
				cursor: pointer;
				color: #ffffff;
				font-size: 17px;
				font-weight: bold;
				padding: 14px 40px;
				text-decoration: none;
				text-shadow: 0px 1px 0px #2f6627;

				&:hover {
					background: linear-gradient(to bottom, #5cbf2a 5%, #44c767 100%);
					background-color: #5cbf2a;
				}
			}
		}
	}
}
