.form {
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding: 10px;
	background-color: #2a4e9d;
	padding: 10px;
	border-radius: 4px;
	width: 100%;

	.formInput {
		display: flex;
		margin-top: 10px;
		font-weight: normal;
		color: lightcyan;
		margin-right: 5px;
		gap: 10px;
		align-items: center;

		label {
			width: 150px;
			font-size: 16px;
			font-weight: bold;
			text-align: left;
		}
		.form-field {
			height: 36px;
			width: 55%;
			padding: 0 16px;
			outline: 0;
			transition: 0.2s;
			font-size: 16px;
			background-color: white;
			border-radius: 4px;
			line-height: 1.5em;
		}
		.form-field-zip {
			height: 36px;
			width: 15%;
			padding: 0 16px;
			outline: 0;
			transition: 0.2s;
			font-size: 16px;
			background-color: white;
			border-radius: 4px;
			line-height: 1.5em;
		}
	}
	.selectCntrl {
		display: flex;
		// margin-top: 10px;
		// margin-right: 30px;
		font-weight: normal;
		color: #2a4e9d;
		margin-right: 5px;
		gap: 10px;
		align-items: center;
		width: 100%;

		label {
			width: 150px;
			font-size: 16px;
			font-weight: bold;
			color: lightcyan;
		}

		.select {
			background-color: white;
			// border: thin solid #2a4e9d;
			border-radius: 4px;
			display: inline-block;
			font-size: 14px;
			line-height: 1.5em;
			padding: 0.5em 3.5em 0.5em 1em;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			-webkit-appearance: none;
			-moz-appearance: none;
			background-image: linear-gradient(45deg, transparent 50%, #2a4e9d 50%),
				linear-gradient(135deg, #2a4e9d 50%, transparent 50%),
				linear-gradient(to right, skyblue, skyblue);
			background-position: calc(100% - 20px) calc(1em + 2px),
				calc(100% - 15px) calc(1em + 2px), 100% 0;
			background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
			background-repeat: no-repeat;
		}
	}
}
