.dialog {
	border-radius: 4;
	color: #2a4e9d;
	border: 1px solid #2a4e9d;
}

.button {
	border-radius: 0;
	text-transform: "none";
	padding: 5;
	background-color: #2a4e9d;
	color: lightcyan;
}

.logout {
	color: "#fff";
	background-color: red;
	&:hover {
		background-color: red;
	}
}

countdown {
	color: red;
}
